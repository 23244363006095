//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyArdHl9GGulaIN38bZ3S0V8JQ1l7ukbgp8",
//   authDomain: "oasis-ede96.firebaseapp.com",
//   projectId: "oasis-ede96",
//   storageBucket: "oasis-ede96.appspot.com",
//   messagingSenderId: "763467648545",
//   appId: "1:763467648545:web:9977ee3f2c955303d99d60",
//   measurementId: "G-P05BXXFERX",
// };
const firebaseConfig = {
  apiKey: "AIzaSyD246z4cLOESWh901gC4RAaI0tZv8Zo9jM",
  authDomain: "test-b9bd4.firebaseapp.com",
  projectId: "test-b9bd4",
  storageBucket: "test-b9bd4.appspot.com",
  messagingSenderId: "589993068671",
  appId: "1:589993068671:web:02c54abdad460d2fe8574a",
  // measurementId: "G-P05BXXFERX",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
